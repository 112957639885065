export default {
  index: {
    Balance: "餘額",
    FrozenBalance: "待入帳",
    Address: "錢包地址",
    Level: "您的等級",
    Viewetails: "查看詳情",
    FundDetails: "資金明細",
    Node: "認購節點",
    SuperNode: "超級節點",
    Buy: "購買",
    GenesisNode: "創世節點",
    VerifyWalletSignature: "請進行錢包驗簽",
    Signature: "驗簽",
    LevelNotHigh: "您的等級無法購買該產品",
    CopySuccessful: "複製成功",
    CopyFailed: "複製失敗",
    Language: "語言切換",
  },
  buy: {
    WalletPaymentRequired: "還需要錢包支付",
    ConfirmPurchase: "確認購買",
    Balance: "餘額",
    PurchaseSuccessful: "購買成功",
    RequestFailed: "請求出錯，請稍後重試",
    PaymentFailed: "支付失敗，請重試",
  },
  withdrawal: {
    Withdrawal: "提現",
    EnterWithdrawalAmount: "請輸入提現金額",
    WithdrawableAmount: "可提現金額",
    ConfirmWithdrawal: "確認提現",
    TheWithdrawalAmountCannotBalance: "提現金額不能超過餘額",
    WithdrawalSuccessful: "提現成功",
    RequestFailed: "請求出錯，請稍後重試",
  },
  invite: {
    PleaseEnterInvitationLink: "請輸入邀請地址",
    ConfirmLink: "確認綁定",
    BindingSuccessful: "綁定成功",
    RequestFailed: "請求出錯，請稍後重試",
  },
  pay: {
    Recharge: "充值",
    CopyAddress: "複製地址",
    CopySuccessful: "複製成功",
    CopyFailed: "複製失敗",
  },
  fund: {
    FundDetails: "資金明細",
    Time: "時間",
    Type: "類型",
    Amount: "金額",
  },
  team: {
    Myteam: "我的團隊",
    Address: "錢包地址",
    Time: "時間",
    Level: "級別",
  },
  equity: {
    TuringSystemNodeRights: "圖靈系統節點權益",
    SuperNode: "超級節點",
    NodeSubscriptionBenefits: "節點認購福利",
    Enjoybenefitsgunpoolnode: "享受機槍池節點",
    DailyBonus: "日分紅",
    And: "和",
    MonthlyBonus: "月分紅",
    Levelbasedrewards: "級別贈送權益",
    Supernodrewards: "超級節點:贈送圖靈系統V1級別",
    Directreferralbenefits: "節點直推權益",
    Earnbonuscommissioneachreferral: "每次直推享有10%分紅佣金",
    GenesisNode: "創世節點",
    Genesisrewards: "創世節點:贈送圖靈系統V2級別",
    Upgradefromsupernodes: "V1 升級為 V2:需直推 10 個超級節點",
    UpgradefromenesisNode: "V2 升級為 V3:需直推 10 個創世節點",
    Rewardincentive: "獎勳與激勵機制",
    AchievementReward: "達標獎勳",
    Eachpromotionrewards: "每次晉升可獲得USDT或代幣獎勳",
    Flexiblepromotion: "靈活晉升",
    Cumulativeteamdevelopment: "團隊業績累積,多線發展收益更多",
    Nodeearnings: "節點收益",
    Levelingunlocksprivileges: "晉級解鎖更多分紅與管理權限",
  },
};
