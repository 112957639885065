export default {
  index: {
    Balance: "Balance",
    FrozenBalance: "Fending credit",
    Address: "Wallet Address",
    Level: "Your Level",
    Viewetails: "View Details",
    FundDetails: "Fund Details",
    Node: "Node Subscription",
    SuperNode: "Super Node",
    Buy: "Buy",
    GenesisNode: "Genesis Node",
    VerifyWalletSignature: "Please verify wallet signature",
    Signature: "Signature",
    LevelNotHigh: "Your level is not high enough to purchase this product",
    CopySuccessful: "Copy successful",
    CopyFailed: "Copy failed",
    Language: "Language switching",
  },
  buy: {
    WalletPaymentRequired: "Wallet payment required",
    ConfirmPurchase: "Confirm Purchase",
    Balance: "Balance",
    PurchaseSuccessful: "Purchase successful",
    RequestFailed: "Request failed, please try again later",
    PaymentFailed: "Payment failed, please try again",
  },
  withdrawal: {
    Withdrawal: "Withdrawal",
    EnterWithdrawalAmount: "Please enter withdrawal amount",
    WithdrawableAmount: "Withdrawable amount",
    ConfirmWithdrawal: "Confirm Withdrawal",
    TheWithdrawalAmountCannotBalance: "Withdrawal amount cannot exceed balance",
    WithdrawalSuccessful: "Withdrawal successful",
    RequestFailed: "Request failed, please try again later",
  },
  invite: {
    PleaseEnterInvitationLink: "Please enter the invitation address",
    ConfirmLink: "Confirm Link",
    BindingSuccessful: "Binding successful",
    RequestFailed: "Request failed, please try again later",
  },
  pay: {
    Recharge: "Recharge",
    CopyAddress: "Copy Address",
    CopySuccessful: "Copy successful",
    CopyFailed: "Copy failed",
  },
  fund: {
    FundDetails: "Fund Details",
    Time: "Time",
    Type: "Type",
    Amount: "Amount",
  },
  team: {
    Myteam: "My Team",
    Address: "Wallet Address",
    Time: "Time",
    Level: "Level",
  },
  equity: {
    TuringSystemNodeRights: "Turing System Node Rights",
    SuperNode: "Super Node",
    NodeSubscriptionBenefits: "Node Subscription Benefits",
    Enjoybenefitsgunpoolnode: "Enjoy Gunpool Node Benefits",
    DailyBonus: "Daily Bonus",
    And: "And",
    MonthlyBonus: "Monthly Bonus",
    Levelbasedrewards: "Level-based Rewards",
    Supernodrewards: "Super Node: Grants Turing System V1 level",
    Directreferralbenefits: "Direct Referral Benefits",
    Earnbonuscommissioneachreferral:
      "Earn 10% commission on each direct referral",
    GenesisNode: "Genesis Node",
    Genesisrewards: "Genesis Node: Grants Turing System V2 level",
    Upgradefromsupernodes:
      "Upgrade from V1 to V2: Requires 10 direct referrals of Super Nodes",
    UpgradefromenesisNode:
      "Upgrade from V2 to V3: Requires 10 direct referrals of Genesis Nodes",
    Rewardincentive: "Reward and Incentive Mechanism",
    AchievementReward: "Achievement Reward",
    Eachpromotionrewards: "Each promotion grants USDT or token rewards",
    Flexiblepromotion: "Flexible Promotion",
    Cumulativeteamdevelopment:
      "Cumulative team development, more earnings from multiple lines",
    Nodeearnings: "Node Earnings",
    Levelingunlocksprivileges:
      "Leveling up unlocks more bonuses and management privileges",
  },
};
