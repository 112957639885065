import { createI18n } from "vue-i18n";
import zh from "./lang/zh.js";
import en from "./lang/en.js";
import tw from "./lang/tw.js";
import ko from "./lang/ko.js";
import ja from "./lang/ja.js";
const messages = {
  'zh-cn':zh,
  en,
  'zh-tw':tw,
  ko,
  ja
};
const getSavedLocale = () => {
  const savedLocale = localStorage.getItem("lang");
  return savedLocale ? savedLocale : "en"; // 默认是英文
};
const i18n = createI18n({
  legacy: false, // 禁用 legacy 模式，启用 Composition API 模式
  locale: getSavedLocale(), // 设置当前语言
  fallbackLocale: "en", // 默认语言
  messages: messages, // 语言包
});

export default i18n;
