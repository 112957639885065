export default {
  index: {
    Balance: "余额",
    FrozenBalance: "待入账",
    Address: "钱包地址",
    Level: "您的级别",
    Viewetails: "查看详情",
    FundDetails: "资金明细",
    Node: "认购节点",
    SuperNode: "超级节点",
    Buy: "购买",
    GenesisNode: "创世节点",
    VerifyWalletSignature: "请进行钱包验签",
    Signature: "验签",
    LevelNotHigh: "您的级别无法购买该产品",
    CopySuccessful: "复制成功",
    CopyFailed: "复制失败",
    Language: "语言切换",
  },
  buy: {
    WalletPaymentRequired: "还需要钱包支付",
    ConfirmPurchase: "确认购买",
    Balance: "余额",
    PurchaseSuccessful: "购买成功",
    RequestFailed: "请求出错，请稍后重试",
    PaymentFailed: "支付失败，请重试",
  },
  withdrawal: {
    Withdrawal: "提现",
    EnterWithdrawalAmount: "请输入提现金额",
    WithdrawableAmount: "可提现金额",
    ConfirmWithdrawal: "确认提现",
    TheWithdrawalAmountCannotBalance: "提现金额不能超过余额",
    WithdrawalSuccessful: "提现成功",
    RequestFailed: "请求出错，请稍后重试",
  },
  invite: {
    PleaseEnterInvitationLink: "请输入邀请地址",
    ConfirmLink: "确认绑定",
    BindingSuccessful: "绑定成功",
    RequestFailed: "请求出错，请稍后重试",
  },
  pay: {
    Recharge: "充值",
    CopyAddress: "复制地址",
    CopySuccessful: "复制成功",
    CopyFailed: "复制失败",
  },
  fund: {
    FundDetails: "资金明细",
    Time: "时间",
    Type: "类型",
    Amount: "金额",
  },
  team: {
    Myteam: "我的团队",
    Address: "钱包地址",
    Time: "时间",
    Level: "级别",
  },
  equity: {
    TuringSystemNodeRights: "图灵系统节点权益",
    SuperNode: "超级节点",
    NodeSubscriptionBenefits: "节点认购福利",
    Enjoybenefitsgunpoolnode: "享受机枪池节点",
    DailyBonus: "日分红",
    And: "和",
    MonthlyBonus: "月分红",
    Levelbasedrewards: "级别赠送权益",
    Supernodrewards: "超级节点:赠送图灵系统V1级别",
    Directreferralbenefits: "节点直推权益",
    Earnbonuscommissioneachreferral: "每次直推享有10%分红佣金",
    GenesisNode: "创世节点",
    Genesisrewards: "创世节点:赠送图灵系统V2级别",
    Upgradefromsupernodes: "V1 升级为 V2:需直推 10 个超级节点",
    UpgradefromenesisNode: "V2 升级为 V3:需直推 10 个创世节点",
    Rewardincentive: "奖励与激励机制",
    AchievementReward: "达标奖励",
    Eachpromotionrewards: "每次晋升可获得USDT或代币奖励",
    Flexiblepromotion: "灵活晋升",
    Cumulativeteamdevelopment: "团队业绩累计,多线发展收益更多",
    Nodeearnings: "节点收益",
    Levelingunlocksprivileges: "晋级解锁更多分红与管理权限",
  },
};