export default {
  index: {
    Balance: "残高",
    FrozenBalance: "入金待ち",
    Address: "ウォレットアドレス",
    Level: "あなたのレベル",
    Viewetails: "詳細を見る",
    FundDetails: "資金明細",
    Node: "ノードの購入",
    SuperNode: "スーパーノード",
    Buy: "購入",
    GenesisNode: "ジェネシスノード",
    VerifyWalletSignature: "ウォレット署名を確認してください",
    Signature: "署名",
    LevelNotHigh: "あなたのレベルではこの製品を購入できません",
    CopySuccessful: "コピー成功",
    CopyFailed: "コピー失敗",
    Language: "言語切替",
  },
  buy: {
    WalletPaymentRequired: "ウォレットでの支払いが必要です",
    ConfirmPurchase: "購入確認",
    Balance: "残高",
    PurchaseSuccessful: "購入成功",
    RequestFailed: "リクエストエラー、後で再試行してください",
    PaymentFailed: "支払い失敗、再試行してください",
  },
  withdrawal: {
    Withdrawal: "引き出し",
    EnterWithdrawalAmount: "引き出し額を入力してください",
    WithdrawableAmount: "引き出し可能な額",
    ConfirmWithdrawal: "引き出し確認",
    TheWithdrawalAmountCannotBalance:
      "引き出し額は残高を超えることはできません",
    WithdrawalSuccessful: "引き出し成功",
    RequestFailed: "リクエストエラー、後で再試行してください",
  },
  invite: {
    PleaseEnterInvitationLink: "招待住所を入力してください",
    ConfirmLink: "リンクを確認",
    BindingSuccessful: "バインディング成功",
    RequestFailed: "リクエストエラー、後で再試行してください",
  },
  pay: {
    Recharge: "チャージ",
    CopyAddress: "アドレスをコピー",
    CopySuccessful: "コピー成功",
    CopyFailed: "コピー失敗",
  },
  fund: {
    FundDetails: "資金明細",
    Time: "時間",
    Type: "タイプ",
    Amount: "金額",
  },
  team: {
    Myteam: "私のチーム",
    Address: "ウォレットアドレス",
    Time: "時間",
    Level: "レベル",
  },
  equity: {
    TuringSystemNodeRights: "チューリングシステムノードの権利",
    SuperNode: "スーパーノード",
    NodeSubscriptionBenefits: "ノード購読の特典",
    Enjoybenefitsgunpoolnode: "ガンプールノードの特典を享受",
    DailyBonus: "デイリーボーナス",
    And: "と",
    MonthlyBonus: "月間ボーナス",
    Levelbasedrewards: "レベルに基づく報酬",
    Supernodrewards: "スーパーノード: チューリングシステムV1レベルを進呈",
    Directreferralbenefits: "ノード直接推薦の特典",
    Earnbonuscommissioneachreferral:
      "直接推薦ごとに10%のボーナスコミッションを獲得",
    GenesisNode: "ジェネシスノード",
    Genesisrewards: "ジェネシスノード: チューリングシステムV2レベルを進呈",
    Upgradefromsupernodes:
      "V1からV2にアップグレード: スーパーノード10個の直接推薦が必要",
    UpgradefromenesisNode:
      "V2からV3にアップグレード: ジェネシスノード10個の直接推薦が必要",
    Rewardincentive: "報酬とインセンティブメカニズム",
    AchievementReward: "達成報酬",
    Eachpromotionrewards: "各昇格でUSDTまたはトークン報酬を獲得",
    Flexiblepromotion: "柔軟な昇格",
    Cumulativeteamdevelopment:
      "チームの業績の累積、複数のラインでより多くの収益",
    Nodeearnings: "ノード収益",
    Levelingunlocksprivileges:
      "レベルアップでより多くのボーナスと管理権限が解放される",
  },
};
