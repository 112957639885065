
import { createRouter, createWebHashHistory } from 'vue-router'

// const routerHistory = createWebHistory()
const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: () => import("../home/index.vue"),
    meta: { title: "首页", keepAlive: false },
  },
  // {
  //   path: "/web",
  //   name: "web",
  //   component: () => import("../home/web.vue"),
  //   meta: { title: "web", keepAlive: false },
  // },
  // {
  //   path: "/dome",
  //   name: "dome",
  //   component: () => import("../components/index.vue"),
  //   meta: { title: "首页", keepAlive: false },
  // },
  // {
  //     path: '/blind',
  //     name: 'blind',
  //     component: () => import("../src/blind/index.vue"),
  //     meta: { title: '盲盒竞猜', keepAlive: false }
  // },
  // {
  //     path: '/bill',
  //     name: 'bill',
  //     component: () => import("../src/index/bill.vue"),
  //     meta: { title: '明细', keepAlive: false }
  // },
  // {
  //     path: '/haveCard',
  //     name: 'haveCard',
  //     component: () => import("../src/index/haveCard.vue"),
  //     meta: { title: '我拥有的卡牌', keepAlive: false }
  // },
  // {
  //     path: '/team',
  //     name: 'team',
  //     component: () => import("../src/index/team.vue"),
  //     meta: { title: '我的团队', keepAlive: false }
  // },
  // // new
  // {
  //     path: '/balance',
  //     name: 'balance',
  //     component: () => import("../src/index/balance/index.vue"),
  //     meta: { title: 'VCE余额', keepAlive: false }
  // },
  // {
  //     path: '/card',
  //     name: 'card',
  //     component: () => import("../src/index/card/index.vue"),
  //     meta: { title: '卡牌专区', keepAlive: false }
  // },
  // {
  //     path: '/blind',
  //     name: 'blind',
  //     component: () => import("../src/index/blind/index.vue"),
  //     meta: { title: '盲盒竞猜', keepAlive: false }
  // },   {
  //     path: '/betting',
  //     name: 'betting',
  //     component: () => import("../src/index/blind/betting.vue"),
  //     meta: { title: '盲盒竞猜', keepAlive: true }
  // },
  // {
  //     path: '/winning',
  //     name: 'winning',
  //     component: () => import("../src/index/blind/winning.vue"),
  //     meta: { title: '中奖记录', keepAlive: false }
  // },
  // {
  //     path: '/rule',
  //     name: 'rule',
  //     component: () => import("../src/index/blind/rule.vue"),
  //     meta: { title: '参与规则', keepAlive: false }
  // },
];

const router = createRouter({
    history: createWebHashHistory(),//修改后
    routes
})
// router.beforeEach((to, from, next) => {
    // console.log(to.path);
    // console.log(from);
    // console.log(localStorage.getItem('token'));
    // if(localStorage.getItem('token')) {
    //   next('/home')
    // }else {

    // }
    // if (localStorage.getItem('token')) {
    //     next()
    // } else {
    //     // Toast('请先登录')
    //     // router.push({
    //     //   path: "/",
    //     // });
    //     next('/')
    // }
    // if (to.path === '/login' || to.path === '/' || to.path === '/start' || to.path === '/register' || to.path === '/forget') { next() } else {
    //     if (localStorage.getItem('token')) {
    //         next()
    //     } else {
    //         // Toast('请先登录')
    //         // router.push({
    //         //   path: "/",
    //         // });
    //         next('/')
    //     }
    // }

// })
export default router