export default {
  index: {
    Balance: "잔액",
    FrozenBalance: "입금 대기",
    Address: "지갑 주소",
    Level: "당신의 레벨",
    Viewetails: "상세 보기",
    FundDetails: "자금 내역",
    Node: "구매 노드",
    SuperNode: "슈퍼 노드",
    Buy: "구매",
    GenesisNode: "창세 노드",
    VerifyWalletSignature: "지갑 서명을 확인하십시오",
    Signature: "서명",
    LevelNotHigh: "귀하의 레벨로는 이 제품을 구매할 수 없습니다",
    CopySuccessful: "복사 성공",
    CopyFailed: "복사 실패",
    Language: "언어 전환",
  },
  buy: {
    WalletPaymentRequired: "지갑 결제가 필요합니다",
    ConfirmPurchase: "구매 확인",
    Balance: "잔액",
    PurchaseSuccessful: "구매 성공",
    RequestFailed: "요청 오류, 나중에 다시 시도해주세요",
    PaymentFailed: "결제 실패, 다시 시도해주세요",
  },
  withdrawal: {
    Withdrawal: "출금",
    EnterWithdrawalAmount: "출금 금액을 입력하십시오",
    WithdrawableAmount: "출금 가능한 금액",
    ConfirmWithdrawal: "출금 확인",
    TheWithdrawalAmountCannotBalance: "출금 금액은 잔액을 초과할 수 없습니다",
    WithdrawalSuccessful: "출금 성공",
    RequestFailed: "요청 오류, 나중에 다시 시도해주세요",
  },
  invite: {
    PleaseEnterInvitationLink: "초대 주소를 입력하세요",
    ConfirmLink: "링크 확인",
    BindingSuccessful: "바인딩 성공",
    RequestFailed: "요청 오류, 나중에 다시 시도해주세요",
  },
  pay: {
    Recharge: "충전",
    CopyAddress: "주소 복사",
    CopySuccessful: "복사 성공",
    CopyFailed: "복사 실패",
  },
  fund: {
    FundDetails: "자금 내역",
    Time: "시간",
    Type: "유형",
    Amount: "금액",
  },
  team: {
    Myteam: "내 팀",
    Address: "지갑 주소",
    Time: "시간",
    Level: "급별",
  },
  equity: {
    TuringSystemNodeRights: "튜링 시스템 노드 권리",
    SuperNode: "슈퍼 노드",
    NodeSubscriptionBenefits: "노드 가입 혜택",
    Enjoybenefitsgunpoolnode: "기총풀 노드 혜택을 즐기세요",
    DailyBonus: "일일 보너스",
    And: "와",
    MonthlyBonus: "월간 보너스",
    Levelbasedrewards: "레벨 기반 보상",
    Supernodrewards: "슈퍼 노드: 튜링 시스템 V1 레벨 지급",
    Directreferralbenefits: "노드 직접 추천 혜택",
    Earnbonuscommissioneachreferral: "직접 추천 시 매번 10% 보너스 커미션",
    GenesisNode: "창세 노드",
    Genesisrewards: "창세 노드: 튜링 시스템 V2 레벨 지급",
    Upgradefromsupernodes:
      "V1에서 V2로 업그레이드: 슈퍼 노드 10개 직속 추천 필요",
    UpgradefromenesisNode:
      "V2에서 V3로 업그레이드: 창세 노드 10개 직속 추천 필요",
    Rewardincentive: "보상 및 인센티브 체계",
    AchievementReward: "목표 달성 보상",
    Eachpromotionrewards: "각 승진 시 USDT 또는 토큰 보상 획득",
    Flexiblepromotion: "유연한 승진",
    Cumulativeteamdevelopment: "팀 성과 누적, 다선 발전으로 더 많은 수익",
    Nodeearnings: "노드 수익",
    Levelingunlocksprivileges:
      "레벨업으로 더 많은 보너스와 관리 권한 잠금 해제",
  },
};
