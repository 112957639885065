<template>
  <!-- <Index /> -->
<router-view></router-view>
</template>

<script setup>
import { ref, onMounted } from 'vue';
const Web3 = require('web3')
const web3 = new Web3(window.ethereum);
const accounts = ref([]);
const contract = ref(null);
const isMetaMaskConnected = ref(false);
// onMounted(async () => {
//   try {
//     await window.ethereum.request({ method: 'eth_requestAccounts' }).then((info) => {
//       console.log(info[0]);
//       generatePrivateKey(info[0])
//     });
//     isMetaMaskConnected.value = true;
//     accounts.value = await web3.eth.getAccounts();
//   } catch (error) {
//     console.error('Error connecting to MetaMask:', error);
//   }
// });
const generatePrivateKey = (info) => {
  // return new Promise((resolve, reject) => {
  //   web3.eth.getAccounts(function (err, res) {
  //     if (err) { reject(err); } else { resolve(res[0]); }
  //   });
  // });
  let string = 'qwertyuiopasdfghjklzxcvbnm';
  web3.eth.personal.sign(string, info, (err, sign) => {
    if (err) {
      console.error(err, 'err');
   
    } else {
      console.log(sign, 'sign');
      web3.eth.personal.ecRecover(string, sign).then(res => {
        localStorage.setItem('address', res)
        console.log(res,'验签确认后的地址')//获得该签名者的地址
      })
      // uni.setStorageSync('sign', sign)
      // this.login(info[0]);
      // this.money_address = info[0]
      // })
    }
  })
}
</script>

<style>
@import "assets/css/base.css";

html,
body,
#app {
  width: 100%;
  height: 100%;
}
.van-toast__text {
font-size: 30px;
}
</style>
