import { createApp } from 'vue'
import App from './App.vue'
import './libs/rem.js'
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
import router from './router/index'
import { createPinia } from 'pinia';
import i18n from "./i18n";
const app = createApp(App)
const pinia = createPinia();

app.config.globalProperties.$axios = axios
app.use(i18n);
app.use(router)
app.use(Vant)
app.use(pinia)
// app.use(axios)
app.mount('#app');